import { Buffer } from 'buffer'
import { type FeathersService, feathers } from '@feathersjs/feathers'
import authenticationClient from '@feathersjs/authentication-client'
import { OFetch, createPiniaClient } from 'feathers-pinia'
import { useStorage } from '@vueuse/core'

// rest imports for the server
import { $fetch } from 'ofetch'
import rest from '@feathersjs/rest-client'

export function isTokenExpired (token) {
  const expiry = (JSON.parse(atob(token.split('.')[1]))).exp
  return (Math.floor((new Date()).getTime() / 1000)) >= expiry
}

type ServiceTypes = Record<string, FeathersService>

/**
 * Creates a Feathers Rest client for the SSR server and a Socket.io client for the browser.
 * Also provides a cookie-storage adapter for JWT SSR using Nuxt APIs.
 */
export default defineNuxtPlugin((nuxt) => {
  const config = useRuntimeConfig()
  const hash = useHashids()
  const host = config.app.apiUrl as string || 'http://localhost:3030'

  const apiFetch = $fetch.create({
    baseUrl: host,
    async onRequest ({ options }) {
      const accessToken = process.client && sessionStorage.getItem('app_token')
      const tokenExpired = accessToken && isTokenExpired(accessToken)

      options.headers = options.headers || {}
      options.headers['x-client'] = 'haussmann'
      options.headers = {
        ...options.headers,
        ...useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
      }

      if (!accessToken || tokenExpired) {
        const timestamp = Date.now()
        const homelooptoken = hash.encodeHex(Buffer.from('haussmann_' + timestamp).toString('hex'))
        const { accessToken } = await $fetch(`${host}/authentication`, {
          method: 'POST',
          headers: {
            'x-client': 'haussmann',
            authorization: 'Bearer ' + homelooptoken,
            ...useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
          }
        })
        process.client && sessionStorage.setItem('app_token', accessToken)
        options.headers.Authorization = 'Bearer ' + accessToken

        return
      }

      options.headers.Authorization = 'Bearer ' + accessToken
    }
  })

  // Store JWT in a cookie for SSR.
  const storageKey = 'feathers-jwt'
  const jwt = useStorage(storageKey, '')
  const storage = {
    getItem: () => jwt.value,
    setItem: (_, val: string) => (jwt.value = val),
    removeItem: () => (jwt.value = null)
  }

  // Use Rest for the SSR Server and socket.io for the browser
  const connection = rest(host).fetch(apiFetch, OFetch)

  // create the feathers client
  const feathersClient = feathers<ServiceTypes>()
    .configure(connection)
    .configure(authenticationClient({ storage, storageKey }))

  // wrap the feathers client
  const api = createPiniaClient(feathersClient, {
    pinia: nuxt.$pinia,
    ssr: !!process.server,
    idField: 'id',
    whitelist: [],
    paramsForServer: [],
    skipGetIfExists: true,
    customSiftOperators: {}
  })

  return { provide: { api } }
})
