export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const faviconUrl = config.app.cdnURL ? `${config.app.cdnURL}/favicon.ico` : '/favicon.ico'

  return useHead({
    link: [
      { rel: 'icon', type: 'image/x-icon', href: faviconUrl }
    ]
  })
})
