export default defineNuxtRouteMiddleware((to) => {
  const redirections = [
    { from: '/acheter/annonces/region/paris-ile-de-france', to: '/acheter/annonces/region/paris' },
    { from: '/moncompte/connexion', to: '/' },
    { from: '/moncompte', to: '/' },
    { from: '/moncompte/informations-personnelles', to: '/' },
    { from: '/financement/contact', to: '/financement' },
    { from: '/financement/vous-connaitre', to: '/financement' },
    { from: '/financement/confirmation', to: '/financement' },
    { from: '/financement/votre-projet/bien', to: '/financement' },
    { from: '/vendre/immobilier', to: '/' },
    { from: '/on-recrute-sur-paris', to: '/recrutement' },
    { from: '/on-recrute-sur-le-var', to: '/recrutement' },
    { from: '/on-recrute-sur-lyon', to: '/recrutement' },
    { from: '/vendre', to: '/' }
  ]

  const redirection = redirections.find((redirection: any) => redirection.from === to.path)

  if (redirection) {
    return navigateTo(redirection.to, { redirectCode: 301 })
  }
})
