import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('scroll-lock', {
    // On mount (inserted)
    mounted (el, binding) {
      if (binding.value) {
        disableBodyScroll(el)
      }
    },

    updated (el, binding) {
      if (binding.value) {
        disableBodyScroll(el)
      } else {
        enableBodyScroll(el)
      }
    },

    // On unmount (removed)
    unmounted (el) {
      enableBodyScroll(el)
    },

    getSSRProps () {
      // you can provide SSR-specific props here
      return {}
    }
  })
})
