import { default as loginLURCk8YTKdMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/admin/login.vue?macro=true";
import { default as indexUTfvVwDyDSMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/admin/single-form/index.vue?macro=true";
import { default as _91type_931VymHQhejqMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/booking/[homelooper]/[type].vue?macro=true";
import { default as _91employeeName_93RupaeAnCsoMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/business-card/[employeeName].vue?macro=true";
import { default as _91propertyString_93S27b6bMtjPMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/[propertyString].vue?macro=true";
import { default as indexO3I4WLWrnqMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/index.vue?macro=true";
import { default as _91offerString_93bnvZmSPAUbMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/offer/[offerString].vue?macro=true";
import { default as _91city_93ljKlfX1GfHMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/region/[city].vue?macro=true";
import { default as indexY2biLUfgKdMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/region/index.vue?macro=true";
import { default as buying_45projectfdVOfgcUsNMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/buying-project.vue?macro=true";
import { default as _91visitIdHash_93kh1Eydfk8VMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/visit/[propertyId]/cancel/[visitIdHash].vue?macro=true";
import { default as indexj4lmouHfdNMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/buy/visit/[propertyId]/index.vue?macro=true";
import { default as contactXiLsRvgyTpMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/contact.vue?macro=true";
import { default as indexAFqfklWZXXMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/estimate-benedic/index.vue?macro=true";
import { default as indexo0Jr5zBcsoMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/estimate/index.vue?macro=true";
import { default as faq5OtiH3jdUqMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/faq.vue?macro=true";
import { default as fundingMIUej0DgE6Meta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/funding.vue?macro=true";
import { default as indexwg78iukhsoMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/index.vue?macro=true";
import { default as job0y8TV4iQB2Meta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/job.vue?macro=true";
import { default as _91city_9329Emve6XRbMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/[city].vue?macro=true";
import { default as constructionYSjELjg5E3Meta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/construction.vue?macro=true";
import { default as energy_45leakage8YzYOKoQqNMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/energy-leakage.vue?macro=true";
import { default as homestagingBD6TdlTCsVMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/homestaging.vue?macro=true";
import { default as inheritanceytHzX9VReXMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/inheritance.vue?macro=true";
import { default as mandatQKploiYNKVMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/mandat.vue?macro=true";
import { default as prestigeUNCctLWwW5Meta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/prestige.vue?macro=true";
import { default as pret_45relaisCc7eOuBa3MMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/landing/pret-relais.vue?macro=true";
import { default as legalMentionsDKwUABott5Meta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/legalMentions.vue?macro=true";
import { default as maintenancefnPwI0rnb1Meta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/maintenance.vue?macro=true";
import { default as partnerslkekvYCOXZMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/partners.vue?macro=true";
import { default as recruitmentWmT84IbwAXMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/recruitment.vue?macro=true";
import { default as addressIsJMjHqUATMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/address.vue?macro=true";
import { default as buying_projectavOAjKP0jbMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/buying_project.vue?macro=true";
import { default as contact2drB97FCrxMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/contact.vue?macro=true";
import { default as _91hashedLeadId_93ARXtzqAV5wMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/offer/[hashedLeadId].vue?macro=true";
import { default as profilersh3jA60OXMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/profile.vue?macro=true";
import { default as projectG94fbTYrkyMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/project.vue?macro=true";
import { default as propertyFp37O7bl4BMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell/property.vue?macro=true";
import { default as sellSXRze3KqbvMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sell.vue?macro=true";
import { default as sponsorshipCdmVrwxLkaMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/sponsorship.vue?macro=true";
import { default as styleguideZ0ED5aAqoQMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/styleguide.vue?macro=true";
import { default as whoWeAreZLbT5ZzVEwMeta } from "/builds/homeloop/dev/haussmann-v2-ts/pages/whoWeAre.vue?macro=true";
export default [
  {
    name: loginLURCk8YTKdMeta?.name ?? "admin-login___fr",
    path: loginLURCk8YTKdMeta?.path ?? "/admin/login",
    meta: loginLURCk8YTKdMeta || {},
    alias: loginLURCk8YTKdMeta?.alias || [],
    redirect: loginLURCk8YTKdMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: indexUTfvVwDyDSMeta?.name ?? "admin-single-form___fr",
    path: indexUTfvVwDyDSMeta?.path ?? "/formulaire-unique",
    meta: indexUTfvVwDyDSMeta || {},
    alias: indexUTfvVwDyDSMeta?.alias || [],
    redirect: indexUTfvVwDyDSMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/admin/single-form/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_931VymHQhejqMeta?.name ?? "booking-homelooper-type___fr",
    path: _91type_931VymHQhejqMeta?.path ?? "/booking/:homelooper()/:type()",
    meta: _91type_931VymHQhejqMeta || {},
    alias: _91type_931VymHQhejqMeta?.alias || [],
    redirect: _91type_931VymHQhejqMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/booking/[homelooper]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91employeeName_93RupaeAnCsoMeta?.name ?? "business-card-employeeName___fr",
    path: _91employeeName_93RupaeAnCsoMeta?.path ?? "/business-card/:employeeName()",
    meta: _91employeeName_93RupaeAnCsoMeta || {},
    alias: _91employeeName_93RupaeAnCsoMeta?.alias || [],
    redirect: _91employeeName_93RupaeAnCsoMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/business-card/[employeeName].vue").then(m => m.default || m)
  },
  {
    name: _91propertyString_93S27b6bMtjPMeta?.name ?? "buy-ads-propertyString___fr",
    path: _91propertyString_93S27b6bMtjPMeta?.path ?? "/acheter/annonces/:propertyString()",
    meta: _91propertyString_93S27b6bMtjPMeta || {},
    alias: _91propertyString_93S27b6bMtjPMeta?.alias || [],
    redirect: _91propertyString_93S27b6bMtjPMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/[propertyString].vue").then(m => m.default || m)
  },
  {
    name: indexO3I4WLWrnqMeta?.name ?? "buy-ads___fr",
    path: indexO3I4WLWrnqMeta?.path ?? "/acheter/annonces",
    meta: indexO3I4WLWrnqMeta || {},
    alias: indexO3I4WLWrnqMeta?.alias || [],
    redirect: indexO3I4WLWrnqMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/index.vue").then(m => m.default || m)
  },
  {
    name: _91offerString_93bnvZmSPAUbMeta?.name ?? "buy-ads-offer-offerString___fr",
    path: _91offerString_93bnvZmSPAUbMeta?.path ?? "/acheter/annonces/offre/:offerString()",
    meta: _91offerString_93bnvZmSPAUbMeta || {},
    alias: _91offerString_93bnvZmSPAUbMeta?.alias || [],
    redirect: _91offerString_93bnvZmSPAUbMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/offer/[offerString].vue").then(m => m.default || m)
  },
  {
    name: _91city_93ljKlfX1GfHMeta?.name ?? "buy-ads-region-city___fr",
    path: _91city_93ljKlfX1GfHMeta?.path ?? "/acheter/annonces/region/:city()",
    meta: _91city_93ljKlfX1GfHMeta || {},
    alias: _91city_93ljKlfX1GfHMeta?.alias || [],
    redirect: _91city_93ljKlfX1GfHMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/region/[city].vue").then(m => m.default || m)
  },
  {
    name: indexY2biLUfgKdMeta?.name ?? "buy-ads-region___fr",
    path: indexY2biLUfgKdMeta?.path ?? "/acheter/annonces/region",
    meta: indexY2biLUfgKdMeta || {},
    alias: indexY2biLUfgKdMeta?.alias || [],
    redirect: indexY2biLUfgKdMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/ads/region/index.vue").then(m => m.default || m)
  },
  {
    name: buying_45projectfdVOfgcUsNMeta?.name ?? "buy-buying-project___fr",
    path: buying_45projectfdVOfgcUsNMeta?.path ?? "/acheter/projet-achat",
    meta: buying_45projectfdVOfgcUsNMeta || {},
    alias: buying_45projectfdVOfgcUsNMeta?.alias || [],
    redirect: buying_45projectfdVOfgcUsNMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/buying-project.vue").then(m => m.default || m)
  },
  {
    name: _91visitIdHash_93kh1Eydfk8VMeta?.name ?? "buy-visit-propertyId-cancel-visitIdHash___fr",
    path: _91visitIdHash_93kh1Eydfk8VMeta?.path ?? "/visite/:propertyId()/annuler/:visitIdHash()",
    meta: _91visitIdHash_93kh1Eydfk8VMeta || {},
    alias: _91visitIdHash_93kh1Eydfk8VMeta?.alias || [],
    redirect: _91visitIdHash_93kh1Eydfk8VMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/visit/[propertyId]/cancel/[visitIdHash].vue").then(m => m.default || m)
  },
  {
    name: indexj4lmouHfdNMeta?.name ?? "buy-visit-propertyId___fr",
    path: indexj4lmouHfdNMeta?.path ?? "/visite/:propertyId()",
    meta: indexj4lmouHfdNMeta || {},
    alias: indexj4lmouHfdNMeta?.alias || [],
    redirect: indexj4lmouHfdNMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/buy/visit/[propertyId]/index.vue").then(m => m.default || m)
  },
  {
    name: contactXiLsRvgyTpMeta?.name ?? "contact___fr",
    path: contactXiLsRvgyTpMeta?.path ?? "/contact",
    meta: contactXiLsRvgyTpMeta || {},
    alias: contactXiLsRvgyTpMeta?.alias || [],
    redirect: contactXiLsRvgyTpMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexAFqfklWZXXMeta?.name ?? "estimate-benedic___fr",
    path: indexAFqfklWZXXMeta?.path ?? "/estimation-benedic",
    meta: indexAFqfklWZXXMeta || {},
    alias: indexAFqfklWZXXMeta?.alias || [],
    redirect: indexAFqfklWZXXMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/estimate-benedic/index.vue").then(m => m.default || m)
  },
  {
    name: indexo0Jr5zBcsoMeta?.name ?? "estimate___fr",
    path: indexo0Jr5zBcsoMeta?.path ?? "/estimer",
    meta: indexo0Jr5zBcsoMeta || {},
    alias: indexo0Jr5zBcsoMeta?.alias || [],
    redirect: indexo0Jr5zBcsoMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/estimate/index.vue").then(m => m.default || m)
  },
  {
    name: faq5OtiH3jdUqMeta?.name ?? "faq___fr",
    path: faq5OtiH3jdUqMeta?.path ?? "/faq",
    meta: faq5OtiH3jdUqMeta || {},
    alias: faq5OtiH3jdUqMeta?.alias || [],
    redirect: faq5OtiH3jdUqMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: fundingMIUej0DgE6Meta?.name ?? "funding___fr",
    path: fundingMIUej0DgE6Meta?.path ?? "/financement",
    meta: fundingMIUej0DgE6Meta || {},
    alias: fundingMIUej0DgE6Meta?.alias || [],
    redirect: fundingMIUej0DgE6Meta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/funding.vue").then(m => m.default || m)
  },
  {
    name: indexwg78iukhsoMeta?.name ?? "index___fr",
    path: indexwg78iukhsoMeta?.path ?? "/",
    meta: indexwg78iukhsoMeta || {},
    alias: indexwg78iukhsoMeta?.alias || [],
    redirect: indexwg78iukhsoMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/index.vue").then(m => m.default || m)
  },
  {
    name: job0y8TV4iQB2Meta?.name ?? "job___fr",
    path: job0y8TV4iQB2Meta?.path ?? "/recrutement",
    meta: job0y8TV4iQB2Meta || {},
    alias: job0y8TV4iQB2Meta?.alias || [],
    redirect: job0y8TV4iQB2Meta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/job.vue").then(m => m.default || m)
  },
  {
    name: _91city_9329Emve6XRbMeta?.name ?? "landing-city___fr",
    path: _91city_9329Emve6XRbMeta?.path ?? "/vendre-:city()",
    meta: _91city_9329Emve6XRbMeta || {},
    alias: _91city_9329Emve6XRbMeta?.alias || [],
    redirect: _91city_9329Emve6XRbMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/[city].vue").then(m => m.default || m)
  },
  {
    name: constructionYSjELjg5E3Meta?.name ?? "landing-construction___fr",
    path: constructionYSjELjg5E3Meta?.path ?? "/travaux",
    meta: constructionYSjELjg5E3Meta || {},
    alias: constructionYSjELjg5E3Meta?.alias || [],
    redirect: constructionYSjELjg5E3Meta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/construction.vue").then(m => m.default || m)
  },
  {
    name: energy_45leakage8YzYOKoQqNMeta?.name ?? "landing-energy-leakage___fr",
    path: energy_45leakage8YzYOKoQqNMeta?.path ?? "/passoire-energetique",
    meta: energy_45leakage8YzYOKoQqNMeta || {},
    alias: energy_45leakage8YzYOKoQqNMeta?.alias || [],
    redirect: energy_45leakage8YzYOKoQqNMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/energy-leakage.vue").then(m => m.default || m)
  },
  {
    name: homestagingBD6TdlTCsVMeta?.name ?? "landing-homestaging___fr",
    path: homestagingBD6TdlTCsVMeta?.path ?? "/homestaging",
    meta: homestagingBD6TdlTCsVMeta || {},
    alias: homestagingBD6TdlTCsVMeta?.alias || [],
    redirect: homestagingBD6TdlTCsVMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/homestaging.vue").then(m => m.default || m)
  },
  {
    name: inheritanceytHzX9VReXMeta?.name ?? "landing-inheritance___fr",
    path: inheritanceytHzX9VReXMeta?.path ?? "/heritage",
    meta: inheritanceytHzX9VReXMeta || {},
    alias: inheritanceytHzX9VReXMeta?.alias || [],
    redirect: inheritanceytHzX9VReXMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/inheritance.vue").then(m => m.default || m)
  },
  {
    name: mandatQKploiYNKVMeta?.name ?? "landing-mandat___fr",
    path: mandatQKploiYNKVMeta?.path ?? "/vente-accompagnee",
    meta: mandatQKploiYNKVMeta || {},
    alias: mandatQKploiYNKVMeta?.alias || [],
    redirect: mandatQKploiYNKVMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/mandat.vue").then(m => m.default || m)
  },
  {
    name: prestigeUNCctLWwW5Meta?.name ?? "landing-prestige___fr",
    path: prestigeUNCctLWwW5Meta?.path ?? "/prestige",
    meta: prestigeUNCctLWwW5Meta || {},
    alias: prestigeUNCctLWwW5Meta?.alias || [],
    redirect: prestigeUNCctLWwW5Meta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/prestige.vue").then(m => m.default || m)
  },
  {
    name: pret_45relaisCc7eOuBa3MMeta?.name ?? "landing-pret-relais___fr",
    path: pret_45relaisCc7eOuBa3MMeta?.path ?? "/pret-relais",
    meta: pret_45relaisCc7eOuBa3MMeta || {},
    alias: pret_45relaisCc7eOuBa3MMeta?.alias || [],
    redirect: pret_45relaisCc7eOuBa3MMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/landing/pret-relais.vue").then(m => m.default || m)
  },
  {
    name: legalMentionsDKwUABott5Meta?.name ?? "legalMentions___fr",
    path: legalMentionsDKwUABott5Meta?.path ?? "/mentions-legales",
    meta: legalMentionsDKwUABott5Meta || {},
    alias: legalMentionsDKwUABott5Meta?.alias || [],
    redirect: legalMentionsDKwUABott5Meta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/legalMentions.vue").then(m => m.default || m)
  },
  {
    name: maintenancefnPwI0rnb1Meta?.name ?? "maintenance___fr",
    path: maintenancefnPwI0rnb1Meta?.path ?? "/maintenance",
    meta: maintenancefnPwI0rnb1Meta || {},
    alias: maintenancefnPwI0rnb1Meta?.alias || [],
    redirect: maintenancefnPwI0rnb1Meta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: partnerslkekvYCOXZMeta?.name ?? "partners___fr",
    path: partnerslkekvYCOXZMeta?.path ?? "/partenaires",
    meta: partnerslkekvYCOXZMeta || {},
    alias: partnerslkekvYCOXZMeta?.alias || [],
    redirect: partnerslkekvYCOXZMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: recruitmentWmT84IbwAXMeta?.name ?? "recruitment___fr",
    path: recruitmentWmT84IbwAXMeta?.path ?? "/on-recrute",
    meta: recruitmentWmT84IbwAXMeta || {},
    alias: recruitmentWmT84IbwAXMeta?.alias || [],
    redirect: recruitmentWmT84IbwAXMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/recruitment.vue").then(m => m.default || m)
  },
  {
    name: sellSXRze3KqbvMeta?.name ?? "sell___fr",
    path: sellSXRze3KqbvMeta?.path ?? "/vendre",
    meta: sellSXRze3KqbvMeta || {},
    alias: sellSXRze3KqbvMeta?.alias || [],
    redirect: sellSXRze3KqbvMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell.vue").then(m => m.default || m),
    children: [
  {
    name: addressIsJMjHqUATMeta?.name ?? "sell-address___fr",
    path: addressIsJMjHqUATMeta?.path ?? "saisie-adresse",
    meta: addressIsJMjHqUATMeta || {},
    alias: addressIsJMjHqUATMeta?.alias || [],
    redirect: addressIsJMjHqUATMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/address.vue").then(m => m.default || m)
  },
  {
    name: buying_projectavOAjKP0jbMeta?.name ?? "sell-buying_project___fr",
    path: buying_projectavOAjKP0jbMeta?.path ?? "projet-d-achat",
    meta: buying_projectavOAjKP0jbMeta || {},
    alias: buying_projectavOAjKP0jbMeta?.alias || [],
    redirect: buying_projectavOAjKP0jbMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/buying_project.vue").then(m => m.default || m)
  },
  {
    name: contact2drB97FCrxMeta?.name ?? "sell-contact___fr",
    path: contact2drB97FCrxMeta?.path ?? "contact",
    meta: contact2drB97FCrxMeta || {},
    alias: contact2drB97FCrxMeta?.alias || [],
    redirect: contact2drB97FCrxMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/contact.vue").then(m => m.default || m)
  },
  {
    name: _91hashedLeadId_93ARXtzqAV5wMeta?.name ?? "sell-offer-hashedLeadId___fr",
    path: _91hashedLeadId_93ARXtzqAV5wMeta?.path ?? "offre/:hashedLeadId()",
    meta: _91hashedLeadId_93ARXtzqAV5wMeta || {},
    alias: _91hashedLeadId_93ARXtzqAV5wMeta?.alias || [],
    redirect: _91hashedLeadId_93ARXtzqAV5wMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/offer/[hashedLeadId].vue").then(m => m.default || m)
  },
  {
    name: profilersh3jA60OXMeta?.name ?? "sell-profile___fr",
    path: profilersh3jA60OXMeta?.path ?? "profil",
    meta: profilersh3jA60OXMeta || {},
    alias: profilersh3jA60OXMeta?.alias || [],
    redirect: profilersh3jA60OXMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/profile.vue").then(m => m.default || m)
  },
  {
    name: projectG94fbTYrkyMeta?.name ?? "sell-project___fr",
    path: projectG94fbTYrkyMeta?.path ?? "projet",
    meta: projectG94fbTYrkyMeta || {},
    alias: projectG94fbTYrkyMeta?.alias || [],
    redirect: projectG94fbTYrkyMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/project.vue").then(m => m.default || m)
  },
  {
    name: propertyFp37O7bl4BMeta?.name ?? "sell-property___fr",
    path: propertyFp37O7bl4BMeta?.path ?? "bien",
    meta: propertyFp37O7bl4BMeta || {},
    alias: propertyFp37O7bl4BMeta?.alias || [],
    redirect: propertyFp37O7bl4BMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sell/property.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sponsorshipCdmVrwxLkaMeta?.name ?? "sponsorship___fr",
    path: sponsorshipCdmVrwxLkaMeta?.path ?? "/parrainage",
    meta: sponsorshipCdmVrwxLkaMeta || {},
    alias: sponsorshipCdmVrwxLkaMeta?.alias || [],
    redirect: sponsorshipCdmVrwxLkaMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: styleguideZ0ED5aAqoQMeta?.name ?? "styleguide___fr",
    path: styleguideZ0ED5aAqoQMeta?.path ?? "/styleguide",
    meta: styleguideZ0ED5aAqoQMeta || {},
    alias: styleguideZ0ED5aAqoQMeta?.alias || [],
    redirect: styleguideZ0ED5aAqoQMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/styleguide.vue").then(m => m.default || m)
  },
  {
    name: whoWeAreZLbT5ZzVEwMeta?.name ?? "whoWeAre___fr",
    path: whoWeAreZLbT5ZzVEwMeta?.path ?? "/qui-sommes-nous",
    meta: whoWeAreZLbT5ZzVEwMeta || {},
    alias: whoWeAreZLbT5ZzVEwMeta?.alias || [],
    redirect: whoWeAreZLbT5ZzVEwMeta?.redirect,
    component: () => import("/builds/homeloop/dev/haussmann-v2-ts/pages/whoWeAre.vue").then(m => m.default || m)
  }
]