import { Loader } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const gMapLoader = new Loader({
    apiKey: config.app.googleMapsApiKey as string,
    version: 'weekly'
  })

  return { provide: { gMapLoader } }
})
