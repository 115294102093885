export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return
  }

  to.query.utm_campaign && sessionStorage.setItem('utm_campaign', to.query.utm_campaign as string)
  to.query.utm_medium && sessionStorage.setItem('utm_medium', to.query.utm_medium as string)
  to.query.utm_source && sessionStorage.setItem('utm_source', to.query.utm_source as string)
  to.query.utm_term && sessionStorage.setItem('utm_term', to.query.utm_term as string)

  if (to.query.tm1) {
    sessionStorage.setItem('utm_source', 'Text message')
    sessionStorage.setItem('utm_campaign', 'Rule 1 : Prospection - Nouveau bien')
  } else if (to.query.tm3) {
    sessionStorage.setItem('utm_source', 'Text message')
    sessionStorage.setItem('utm_campaign', 'Rule 3 : Prospection - Baisse de prix')
  }

  if (to.hash) {
    const queryString = new URLSearchParams(to.hash.replace('#', ''))
    queryString.has('utm_campaign') && sessionStorage.setItem('utm_campaign', queryString.get('utm_campaign') || '')
    queryString.has('utm_medium') && sessionStorage.setItem('utm_medium', queryString.get('utm_medium') || '')
    queryString.has('utm_source') && sessionStorage.setItem('utm_source', queryString.get('utm_source') || '')
    queryString.has('utm_term') && sessionStorage.setItem('utm_term', queryString.get('utm_term') || '')

    if (queryString.has('tm1')) {
      sessionStorage.setItem('utm_source', 'Text message')
      sessionStorage.setItem('utm_campaign', 'Rule 1 : Prospection - Nouveau bien')
    } else if (queryString.has('tm3')) {
      sessionStorage.setItem('utm_source', 'Text message')
      sessionStorage.setItem('utm_campaign', 'Rule 3 : Prospection - Baisse de prix')
    }
  }
})
