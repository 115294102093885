import { useStorage } from '@vueuse/core'

export type serviceName = 'rivals' | 'addresses' | 'contacts' | 'characteristics' | 'lifestyles' | 'extensions' | 'constructions' | 'estates' | 'leads' | 'offers' | 'employees'
export type FunnelMode = 'normal' | 'estimate_only'

export const useFunnel = () => {
  const { api } = useFeathers()
  const services: serviceName[] = ['rivals', 'addresses', 'contacts', 'characteristics', 'lifestyles', 'extensions', 'constructions', 'estates', 'leads', 'offers', 'employees']

  function startFunnel () {
    services
      .filter(service => !['employees'].includes(service))
      .forEach((serviceName: serviceName) => {
        const service = api.service(serviceName)
        const newRecord = service.new()
        newRecord.clone()
      })
  }

  function clearFunnel () {
    funnelMode.value = 'normal'
    services.forEach((serviceName: serviceName) => {
      api.service(serviceName).store.clearAll()
    })
  }

  async function saveFunnel () {
    const servicesToSave = services.filter(service => !['contacts', 'rivals', 'offers', 'employees'].includes(service))
    const addresses = getCurrent('addresses')
    const characteristics = getCurrent('characteristics')
    const lifestyles = getCurrent('lifestyles')
    const extensions = getCurrent('extensions')
    const constructions = getCurrent('constructions')
    const estates = getCurrent('estates')
    for (const serviceName of servicesToSave) {
      const current = getCurrent(serviceName)

      if (serviceName === 'estates') {
        current.address_id = addresses.id
        current.characteristic_id = characteristics.id
        current.extension_id = extensions.id
        current.lifestyle_id = lifestyles.id
        current.construction_id = constructions.id
      }

      if (serviceName === 'leads') {
        current.estate_id = estates.id
      }

      await current.save()
    }

    return true
  }

  function getServiceByName (serviceName: serviceName) {
    return api.service(serviceName)
  }

  function setStore ({ service, field }: { service: serviceName, field: string }, value: any) {
    const current = getCurrent(service)
    current[field] = value
  }

  function getCurrent (serviceName: serviceName) {
    const service = api.service(serviceName)
    const clones = service.store.clones
    return clones.length ? clones[0] : false
  }

  function estateTypeIs (key: 'flat' | 'house') {
    const estates = getCurrent('estates')
    return estates.estate_type === key || false
  }

  function contactTypeIs (key: 'vendor' | 'agent') {
    const contacts = getCurrent('contacts')

    if (key === 'agent' && contacts.is_agent) {
      return true
    }

    if (key === 'vendor' && !contacts.is_agent) {
      return true
    }

    return false
  }

  const funnelMode = useStorage<FunnelMode>('funnel-mode', () => 'normal')

  return { startFunnel, getServiceByName, getCurrent, clearFunnel, setStore, estateTypeIs, contactTypeIs, saveFunnel, funnelMode }
}
