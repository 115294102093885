import { Buffer } from 'buffer'
import type { UseFetchOptions } from 'nuxt/app'
import { defu } from 'defu'
import { useFetch } from 'nuxt/app'
import { $fetch } from 'ofetch'
import { isTokenExpired } from '~/plugins/feathers'

// Can't name it useFetch cause it will end with recursion and infinite loop
export function useHFetch<T> (url: string | (() => string), options: UseFetchOptions<T> = {}) {
  // const userAuth = useCookie('token')
  const config = useRuntimeConfig()
  const hash = useHashids()
  const host = config.app.apiUrl as string || 'http://localhost:3030'

  const defaults: UseFetchOptions<T> = {
    baseURL: host,
    key: url,
    async onRequest ({ options }) {
      const accessToken = process.client && sessionStorage.getItem('app_token')
      const tokenExpired = accessToken && isTokenExpired(accessToken)

      options.headers = options.headers || {}
      options.headers['x-client'] = 'haussmann'
      options.headers = {
        ...options.headers,
        ...useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
      }

      if (!accessToken || tokenExpired) {
        const timestamp = Date.now()
        const homelooptoken = hash.encodeHex(Buffer.from('haussmann_' + timestamp).toString('hex'))

        const { accessToken } : any = await $fetch(`${host}/authentication`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + homelooptoken,
            ...useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
          }
        })

        process.client && sessionStorage.setItem('app_token', accessToken)
        options.headers.Authorization = 'Bearer ' + accessToken
        return
      }

      options.headers.Authorization = 'Bearer ' + accessToken
    }
  }

  const params = defu(options, defaults)

  return useFetch(url, params)
}
