
// @ts-nocheck


export const localeCodes =  [
  "fr"
]

export const localeLoaders = {
  "fr": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "fr",
      "name": "Français",
      "language": "fr-FR"
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "legalMentions": {
      "fr": "/mentions-legales"
    },
    "funding": {
      "fr": "/financement"
    },
    "buy/index": {
      "fr": "/acheter"
    },
    "buy/ads/[propertyString]": {
      "fr": "/acheter/annonces/[propertyString]"
    },
    "buy/ads/offer/[offerString]": {
      "fr": "/acheter/annonces/offre/[offerString]"
    },
    "buy/ads/index": {
      "fr": "/acheter/annonces"
    },
    "buy/ads/region/index": {
      "fr": "/acheter/annonces/region"
    },
    "buy/ads/region/[city]": {
      "fr": "/acheter/annonces/region/[city]"
    },
    "buy/visit/[propertyId]/index": {
      "fr": "/visite/[propertyId]"
    },
    "buy/visit/[propertyId]/cancel/[visitIdHash]": {
      "fr": "/visite/[propertyId]/annuler/[visitIdHash]"
    },
    "buy/buying-project": {
      "fr": "/acheter/projet-achat"
    },
    "whoWeAre": {
      "fr": "/qui-sommes-nous"
    },
    "partners": {
      "fr": "/partenaires"
    },
    "sell": {
      "fr": "/vendre"
    },
    "sell/profile": {
      "fr": "/vendre/profil"
    },
    "sell/property": {
      "fr": "/vendre/bien"
    },
    "sell/project": {
      "fr": "/vendre/projet"
    },
    "sell/contact": {
      "fr": "/vendre/contact"
    },
    "sell/address": {
      "fr": "/vendre/saisie-adresse"
    },
    "sell/buying_project": {
      "fr": "/vendre/projet-d-achat"
    },
    "sell/offer/[hashedLeadId]": {
      "fr": "/vendre/offre/[hashedLeadId]"
    },
    "job": {
      "fr": "/recrutement"
    },
    "estimate-benedic/index": {
      "fr": "/estimation-benedic"
    },
    "estimate/index": {
      "fr": "/estimer"
    },
    "landing/[city]": {
      "fr": "/vendre-[city]"
    },
    "landing/mandat": {
      "fr": "/vente-accompagnee"
    },
    "landing/pret-relais": {
      "fr": "/pret-relais"
    },
    "landing/construction": {
      "fr": "/travaux"
    },
    "landing/homestaging": {
      "fr": "/homestaging"
    },
    "landing/inheritance": {
      "fr": "/heritage"
    },
    "landing/energy-leakage": {
      "fr": "/passoire-energetique"
    },
    "landing/prestige": {
      "fr": "/prestige"
    },
    "admin/single-form/index": {
      "fr": "/formulaire-unique"
    },
    "recruitment": {
      "fr": "/on-recrute"
    },
    "sponsorship": {
      "fr": "/parrainage"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr",
    "name": "Français",
    "language": "fr-FR",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
