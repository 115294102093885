// Provides access to Feathers clients
export const useFeathers = () => {
  const { $api: api } = useNuxtApp()

  return { api }
}

export const getService = (serviceName: string) => {
  const { $api } = useNuxtApp()

  return $api.service(serviceName)
}
