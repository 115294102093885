import validate from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45maintenance_45global from "/builds/homeloop/dev/haussmann-v2-ts/middleware/1.maintenance.global.ts";
import _2_45utms_45global from "/builds/homeloop/dev/haussmann-v2-ts/middleware/2.utms.global.ts";
import _3_45redirection_45global from "/builds/homeloop/dev/haussmann-v2-ts/middleware/3.redirection.global.ts";
import manifest_45route_45rule from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45maintenance_45global,
  _2_45utms_45global,
  _3_45redirection_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/builds/homeloop/dev/haussmann-v2-ts/middleware/admin.ts")
}