import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

export default defineNuxtPlugin((nuxtApp) => {
  const options = {
    ssr: true,
    defaultAssets: {
      font: false
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#4C00FF'
          }
        }
      }
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    defaults: {
      VTextField: {
        variant: 'outlined',
        bgColor: 'white'
      },
      VTextarea: {
        variant: 'outlined',
        bgColor: 'white'
      },
      VFileInput: {
        variant: 'outlined',
        bgColor: 'white'
      },
      VSelect: {
        variant: 'outlined',
        bgColor: 'white'
      },
      VDivider: {
        role: 'presentation'
      }
    }
  }

  const vuetify = createVuetify(options)
  nuxtApp.vueApp.use(vuetify)

  return { provide: { vuetify } }
})
