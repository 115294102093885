export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig()
  const isMaintenance = config.app.maintenance

  if (!isMaintenance && to.path === '/maintenance') {
    return navigateTo('/')
  }

  if (isMaintenance && to.path !== '/maintenance') {
    return navigateTo('/maintenance')
  }
})
